<template>
    <div>
    
        <b-row>
            <b-col offset-sm-2 sm="12" md="10" offset-lg="2" lg="8" align-self="center" class="mx-auto">
    
                <b-card class="pricing-basic-value font-weight-bolder text-primary" title="Lordtech Support 🚀">
                    
                    <b-container >
                          <b-row align-v="center" class="mt-3 mb-3">
    
    
                            <b-col cols="6" md="3" class="mb-1 mx-auto ">
                                <b-img  thumbnail size="70px" fluid src="http://localhost:8080/img/logo.0f053023.png" /></b-col>
    
                        </b-row>
                        <b-form @submit.prevent>
    <b-row>
      <b-col cols="12">
        <b-form-group
          label="Address"
          label-for="h-first-name"
          label-cols-md="4"
        >
         <h4>Crystal Mall, 3 Isherin Oshun Rd, Jakande Gate, Isolo, Lagos</h4>
        </b-form-group>
      </b-col>
      <b-col cols="12">
        <b-form-group
          label="Calls"
          label-for="h-email"
          label-cols-md="4"
        >
         <h4>+2348149906899</h4>
        </b-form-group>
      </b-col>
      <b-col cols="12">
        <b-form-group
          label="WhatsApp/Telegram"
          label-for="h-number"
          label-cols-md="4"
        >
         <h4>+2349090429215</h4>
        </b-form-group>
      </b-col>
      <b-col cols="12">
        <b-form-group
          label="Email"
          label-for="h-password"
          label-cols-md="4"
        >
         <h4>help@lordtech.com</h4>
        </b-form-group>
      </b-col>
      <b-col
        md="8"
        offset-md="4"
      >
      
      </b-col>

     
    </b-row>

  </b-form>
                    </b-container>
    
                </b-card>
            </b-col>
    
        </b-row>
    
    
    
    </div>
</template>

<script>
import { BCard, BAvatar, BFormInput, BFormRadio, BForm, BSpinner, BCardText, BButton, BImg, BFormGroup, BContainer, BRow, BCol, BInputGroup } from 'bootstrap-vue'
import vSelect from 'vue-select'
import jwtDefaultConfig from '@/@core/auth/jwt/jwtDefaultConfig'
import Ripple from 'vue-ripple-directive'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
    components: {
        BCard,
        BImg,
     BInputGroup,
vSelect,
        BForm,
        BContainer,
        BSpinner,
        BFormRadio,
        ToastificationContent,
        BFormInput,
        BFormGroup,
        BButton,
        BAvatar,
        BCardText,
        BRow,
        BCol,

    },
    directives: {
        Ripple,
    },


    data() {
        return {
            load: false,
            loadplan: false,
            option: [],
            selected: null,
            showmax: {},
            selectedairtime: null,
            mainurl: jwtDefaultConfig.mainurl,
            phone: null,
            process: false,
            pin: null,
            amount: null,
        }
    },
  
  





}
</script>

